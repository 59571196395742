<template>
    <el-dialog
        width="500px"
        top="40px"
        title="Välj datum"
        :visible="visible"
        :close-on-click-modal="false"
        :before-close="closeModal"
        :lock-scroll="false"
        @open="modalOpen"
        class="modal-edit-payment-container"
    >
        <div class="text-center py-20 space-x-4">
            <el-date-picker v-model="startDate" align="center" placeholder="Start datum" />
            <el-date-picker v-model="endDate" align="center" placeholder="Slut datum" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" :loading="$waiting.is('loading.matching')">Stäng</el-button>
            <el-button @click="selectDates" type="primary">Välj</el-button>
        </span>
    </el-dialog>
</template>
<script>
import queryString from "query-string";
import moment from "moment";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        query: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            startDate: null,
            endDate: null,
        };
    },

    methods: {
        selectDates() {
            const query = queryString.parse(location.search);
            query.startDate = moment(this.startDate).format("YYYY-MM-DD");
            query.endDate = moment(this.endDate).format("YYYY-MM-DD");
            this.$router.replace({ query }).catch(() => {});
            this.closeModal();
        },
        modalOpen() {
            this.startDate = this.query.startDate;
            this.endDate = this.query.endDate;
        },
        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
